import '/public/js/p3core.js';
import '/public/js/gtm-tracking.js';

// Hamburger
$(document).ready(function(){
  $(".hamburger").click(function(){
    $(this).toggleClass("is-active");
  });
});

$( document ).ready(function() {
  // Read more
  $('.expand').find('a[href="#"]').on('click', function (e) {
      e.preventDefault();
      this.expand = !this.expand;
      $(this).text(this.expand?"Read Less...":"Read More...");
      $(this).closest('.expand').find('.hidden-list').slideToggle();
  });

});


// Omni
$('.dropdown-toggle').click(function(){
    $(this).toggleClass('open');
    if($(this).hasClass('open')){
        // $(this).text('hide details');
    } else {
        // $(this).text('view details');
    }
});
$('#trigger').on('click', function(){
    $(".mobile-nav-links").slideToggle();
    $('.blurr').addClass('blurred');
});
$('#close').on('click', function(){
    $(".mobile-nav-links").hide();
    $('.blurr').removeClass('blurred');
});
$('.close-dropdown').on('click', function() {
    $(this).closest('.dropdown').find('.dropdown-toggle').dropdown('toggle');
    $('.dropdown-toggle').removeClass('open');
});

$('.filter').on('click',function (){
    $('.main-filters').slideToggle();
});

$('.hamburger-icon').on('click',function (){
    $('.profile-menu-toggle').slideToggle();
});

$('.close-filter-icon').on('click', function(){
    $('.filters-wrapper').slideUp();
});
$('.info-show').on('click',function (){
    $('.show-more-guest-member-info').slideDown();
});
$('.info-hide').on('click', function(){
    $('.show-more-guest-member-info').slideUp();
});

$('.reset').on('click', function() {
    $('input[type="checkbox"]').prop('checked', false);
});

$('#cancelModifyBtn').on('click', function (e) {
    $('#confirmationModify').slideUp();
});

$('#modifyBookingBtn').on('click', function (e) {
    $('#confirmationModify').slideToggle();
    $("html, body").animate({
        scrollTop: $('#confirmationModify').offset().top - 10
    }, 'slow');
    var check = $('.confirmation-modify-checkboxes input:radio:checked');
    if(check.attr('value') == 999) {
        jQuery('.cancel-options #submitBtn').hide();
    }
});


$('#cancelReservation').change(function() {
    if ($(this).is(':checked')) {
        $('#modifyBtns').slideDown();
        $('#cancelRoomsList').slideUp();
        $('#changeGuests').slideUp();
        $('#changeDates').slideUp();
    }
});

$('#cancelRoom').change(function() {
    if ($(this).is(':checked')) {
        $('#cancelRoomsList').slideDown();
        $('#modifyBtns').slideDown();
        $('#changeGuests').slideUp();
        $('#changeDates').slideUp();
    }
});

$('#changeDetails').change(function() {
    if ($(this).is(':checked')) {
        $('#changeGuests').slideDown();
        $('#modifyBtns').slideDown();
        $('#cancelRoomsList').slideUp();
        $('#changeDates').slideUp();
    }
});

$('#modifyDates').change(function() {
    if ($(this).is(':checked')) {
        $('#changeDates').slideDown();
        $('#modifyBtns').slideDown();
        $('#cancelRoomsList').slideUp();
        $('#changeGuests').slideUp();
    }
});

// $(".profile-nav-wrapper a, .profile-menu-toggle a").click(function() {
//     $('a').removeClass('active');
//     $(this).addClass("active");
// });

$('.close-hamburger-icon').hide();

$('.open-hamburger-icon,.close-hamburger-icon').on('click',function(){
    $('.open-hamburger-icon,.close-hamburger-icon').toggle()
});

$('#addAddress').on('click',function (){
    $(this).closest('.guest-details-address').find('.guest-details-address--basic').slideToggle();
    $('.guest-details-address--full').slideToggle();
});

$('#showForm').on('click',function (){
    $(this).closest('.sign-in-baner').find('.sign-in-baner--open').slideToggle();
    $('.sign-in-baner--basic').slideToggle();
});

// $('#forgotPasswordButton').on('click',function (){
//     $(this).closest('.sign-in-baner').find('.sign-in-baner--reset').slideToggle();
//     $('.sign-in-baner--open').slideToggle();
// });

$('.remove-section').on('click', function(){
    $('.hide-partner-section').hide();
    $('.show-section').show();
});

$('.show-section').on('click', function(){
    $('.hide-partner-section').show();
    $('.show-payment-section').show();
    $('.spouse').addClass('right-alignment');
    $(this).hide();
});
// $(function () {
//     $('[data-toggle="tooltip"]').tooltip()
// })

// Side Nav
if(document.getElementById("openSidebarButton")) {
    function openNav() {
        document.getElementById("mySidenav").classList.add("open");
    }
    document.getElementById("openSidebarButton").addEventListener('click', openNav);
}

if(document.getElementById("closeSidebarButton")) {
    function closeNav() {
        document.getElementById("mySidenav").classList.remove("open");
    }
    document.getElementById("closeSidebarButton").addEventListener('click', closeNav);
}

$('.nav-item--js').click(function () {
    $(this).find('i').toggleClass('rotate180');
    $(this).find('.standard-nav__sub-nav').slideToggle();

    if($(this).find(".menu-icon-chevron-white").hasClass('rotate180')) {
        $(this).find('i').parent().addClass('sub-nav_border-bottom');
    } else {
        $(this).find('i').parent().removeClass('sub-nav_border-bottom');
    }
});

$('.filter-heading').on('click', function(){
    $(this).find('.caret').toggleClass('rotate180');
    $(this).parent().find('.filter-checkboxes').slideToggle();
});

$('.smooth-scroll').click(function(){
    $('html, body').animate({
        scrollTop: $( $(this).attr('href') ).offset().top
    }, 500);
    return false;
});

// $('.rate-heading').click(function(){
//     $(this).closest('.rate-container').find('.package-description').slideToggle();
// });

$('body').on("click", ".dropdown-menu", function (e) {
    $(this).parent().is(".open") && e.stopPropagation();
});

/*
$('.selectall').click(function() {
    if ($(this).is(':checked')) {
        $('.option').prop('checked', true);
        var total = $('input[name="options[]"]:checked').length;
        $(".dropdown-text").html('(' + total + ') Selected');
        $(".select-text").html(' Deselect');
    } else {
        $('.option').prop('checked', false);
        $(".dropdown-text").html('(0) Selected');
        $(".select-text").html(' Select');
    }
});

$("input[type='checkbox'].justone").change(function(){
    var a = $("input[type='checkbox'].justone");
    if(a.length == a.filter(":checked").length){
        $('.selectall').prop('checked', true);
        $(".select-text").html(' Deselect');
    }
    else {
        $('.selectall').prop('checked', false);
        $(".select-text").html(' Select');
    }
});
*/

var container;
var trigger;
var tooltip;
var closeButton;
var expanded = false;

window.addEventListener('DOMContentLoaded', function(e) {
  container = document.querySelector('.tooltip');
  trigger = document.querySelector('.trigger');
  tooltip = document.querySelector('[role="tooltip"]');
  closeButton = document.querySelector('[role="tooltip"] .close-button');

  if (trigger != null) {
      trigger.addEventListener('click', toggleTooltip);
      trigger.addEventListener('keyup', handleTriggerKeyup);
  }
  if (closeButton != null) {
      closeButton.addEventListener('click', function (e) {
          hideTooltip();
          trigger.focus();
      });
  }
});



function showTooltip() {
  trigger.setAttribute('aria-expanded', true);
  expanded = true;
}

function hideTooltip() {
  trigger.setAttribute('aria-expanded', false);
  expanded = false;
}

function toggleTooltip() {
  if(expanded) {
    hideTooltip();
  } else {
    showTooltip();
  }
}

function handleTriggerKeyup(e) {
  switch(e.key) {
    case 'Escape':
      hideTooltip();
      break;
  }
}

$('.slide-trigger').on('click', function (e) {
    $(this).closest('.slide-container').find('.slide-show').slideToggle();
    $(this).closest('.slide-container').find('.slide-hide').slideToggle();
});

$('.slide-trigger-hide').on('click', function (e) {
    $(this).closest('.slide-container').find('.slide-show').slideToggle();
    $(this).closest('.slide-container').find('.slide-hide').slideToggle();
});

$('.slide-section').on('click', function (e) {
    $(this).closest('.slide-container').find('.slide-show').slideToggle();
	$(this).slideToggle();
});


// $('#checkboxBenefits').change(function() {
//    if ($(this).is(':checked')) {
//       $('.guest-details-enroll--form').slideDown();
//    } else {
//       $('.guest-details-enroll--form').slideUp();
//    }
// });

$('#debitCardYes').attr('aria-expanded', 'false');
$('input[type=radio]').click(function() {
    if ($(this).val() === 'No') {
        $('#debitCardYes').attr('aria-expanded', 'true');
    } else {
        $('#debitCardYes').attr('aria-expanded', 'false');
    }
});

$('.hide-section').on('click', function (e) {
    $(this).closest('.slide-container').find('.slide-show').slideToggle();
    $(this).closest('.slide-container').find('.slide-section').slideToggle();
});

$('.delete-payment').on('click', function (e) {
    $('#paymentDetails').slideToggle();
    $('#addPaymentButton').slideToggle();

});

$("#reset-password").on("click", function(){
    $(".reset-info").text("We have sent you a link to reset your password, please check your email.");
});

$('#vieMapBtn').on('click', function (e) {
    $('.no-availability--list').find('.slide-show').slideToggle();
    $(this).closest('.slide-container').find('.slide-hide').slideToggle();
});
$('.view-map').on('click', function (e) {
    const viewMapJqEl = $(this);
    viewMapJqEl.closest('.slide-container').find('.slide-show').slideToggle();
    viewMapJqEl.closest('.slide-container').find('.slide-hide').slideToggle();
    const viewMapsJqObj = $('.view-map');
    viewMapsJqObj.toggleClass('map-marker');
    const mapViewBool = viewMapJqEl.hasClass('map-marker');
    if(mapViewBool){
        viewMapsJqObj.html('<span>View</span> Map <i class="fa fa-map-marker"></i>');
        refreshSlick(document.querySelectorAll(".villas-card:not([hidden])"));
    } else {
        viewMapsJqObj.html('View List');
    }

    document.querySelectorAll('.sort-by-rate').forEach(
        el => el.hidden = !mapViewBool
    );

});
$('.aria-expand').click(function () {
    if ($(this).attr("aria-expanded") === "true") {
        $(this).attr("aria-expanded","false");
    } else {
        $(this).attr("aria-expanded","true");
    }
});
$(".dropdown-toggle--btn").click(function(){
    $(this).find('i').toggleClass('rotate-up');
});

// Timer for membership overwiew page - online checkin.

// let countDownDate = new Date("Sept 29, 2023 13:49:40").getTime();
//
//  function timePart(val,text){
//  return `<div><div class="time">${val}</div><div class="timer-text">${text}</div></div>`
//  }
//  document.querySelector(".timer-btn").style.display = "none";
//  let x = setInterval(function() {
//
//  let now = new Date().getTime();
//
//  let distance = countDownDate - now;
//
//  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
//  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//  let seconds = Math.floor((distance % (1000 * 60)) / 1000);
//
//  days = days <= 9 ? '0' + days : days;
//  hours = hours <= 9 ? '0' + hours : hours;
//  minutes = minutes <= 9 ? '0' + minutes : minutes;
//  seconds = seconds <= 9 ? '0' + seconds : seconds;
//
//  result = timePart(days,'days') + timePart(hours, 'hours') + timePart(minutes,'mins')  + timePart(seconds,'secs');
//  document.getElementById("countdown-timer").innerHTML = result;
//
//  if (distance < 0) {
//  clearInterval(x);
//
//  document.getElementById("countdown-timer").style.display = "none";
//  document.querySelector(".timeup-text").innerHTML = "Online check-in is available now.";
//  document.querySelector(".timer-btn").style.display = "block";
//  }
//  }, 1000);


function setDatepickerPos(input, inst) {
    var rect = input.getBoundingClientRect();
    // use 'setTimeout' to prevent effect overridden by other scripts
    setTimeout(function () {
        var scrollTop = $("body").scrollTop();
        console.log(rect.top);
        console.log(input.offsetHeight);
        console.log(scrollTop);


        inst.dpDiv.css({ top: rect.top + input.offsetHeight + scrollTop});
    }, 0);
}

document.addEventListener('DOMContentLoaded', function() {
    const hamburgerButton = document.querySelector('.hamburger-button');
    if(hamburgerButton){
        hamburgerButton.addEventListener('click', function() {
            this.classList.toggle('open');
        });
    }
});

// $(document).ready(function() {
//     var rotation = 0;
//     $(".dropdown-toggle--btn").click(function() {
//         $(this).closest('.li-meetings').find('.dropdown-container').slideToggle();
//         var $icon = $(this).siblings(".fa");
//         rotation = (rotation + 180) % 360;
//         $icon.css({
//             // Vendor prefixes for better browser compatibility
//             '-webkit-transform': 'rotate(' + rotation + 'deg)',
//             '-moz-transform': 'rotate(' + rotation + 'deg)',
//             '-ms-transform': 'rotate(' + rotation + 'deg)',
//             '-o-transform': 'rotate(' + rotation + 'deg)',
//             'transform': 'rotate(' + rotation + 'deg)'
//         });
//     });
// });
$(document).ready(function() {
    var rotation = 0;

    $(".dropdown-toggle--btn button").click(function() {
        $(this).closest('.li-meetings').find('.dropdown-container').slideToggle();
        var $icon = $(this).siblings(".fa");

        rotation = (rotation + 180) % 360;

        $icon.css({
            '-webkit-transform': 'rotate(' + rotation + 'deg)',
            '-moz-transform': 'rotate(' + rotation + 'deg)',
            '-ms-transform': 'rotate(' + rotation + 'deg)',
            '-o-transform': 'rotate(' + rotation + 'deg)',
            'transform': 'rotate(' + rotation + 'deg)'
        });
    });
});

$(document).ready(function() {
    var maxWidth = 0;
    $('.conf-room-upgrade-container .btn-primary').each(function() {
        if ($(this).outerWidth() > maxWidth) {
            maxWidth = $(this).outerWidth();
        }
    });
    $('.conf-room-upgrade-container .btn-primary').each(function() {
        $(this).outerWidth(maxWidth);
    });
});



export function refreshSlick(elArr) {
    elArr.forEach(el => {
        const slickSliderEl = el.querySelector('.slick-slider');
        if (slickSliderEl) {
            $(slickSliderEl).slick('setPosition');
        }
    });
}

export function createApiUrl(options) {
    'use strict';
    delete options.code;
    delete options.codeType;
    return Object.entries(options)
        .reduce((currentUrl, currentOption) => {
            const [key, value] = currentOption;
            if (value !== undefined && value !== '') {
                return `${currentUrl}/${key}/${value}`;
            } else {
                return currentUrl;
            }
        }, `/api/dynamic-pricing`);
}

document.addEventListener("DOMContentLoaded", () => {
    const accountInfoElList = document.querySelectorAll('[data-account-info]');

    async function pmsProfileDetails() {
        let pmsDetails = {
            "RewardsNumber": "",
            "MembershipLevel": "Error getting status",
            "FreeNights": "Error getting free nights"
        };
        try {
            const headers = new Headers([['X-Requested-With', 'XMLHttpRequest']]);
            const response = await fetch(`/pmsProfileDetails?_token=${encodeURIComponent(document.querySelector("meta[name='csrf-token']").getAttribute("content"))}`, {headers: headers});
            pmsDetails = await response.json();
        } catch (e) {
        }

        accountInfoElList.forEach(el => {
            const accountDropdownEl = el.nextElementSibling;
            accountDropdownEl.querySelector("[data-profile-status]").textContent = pmsDetails["MembershipLevel"];

            // Free Nights
            // const freeNights = pmsDetails["FreeNights"];
            // if (freeNights == 0) {
            //     $('.freeNight.account-header-menu__status').hide()
            // } else {
            //     accountDropdownEl.querySelector("[data-profile-free-nights]").textContent = freeNights;
            // }

            // Tier Dollars
            const tierDollars = pmsDetails["TierDollars"];
            if (tierDollars == 0) {
                if(document.getElementById("statusTierDollarsPC") !==  null) {
                    document.getElementById("statusTierDollarsPC").classList.add("d-none");
                }
                if(document.getElementById("statusTierDollarsMO") !==  null) {
                    document.getElementById("statusTierDollarsMO").classList.add("d-none");
                }
            } else {
                accountDropdownEl.querySelector("[data-profile-tier-dollars]").textContent =  "$" + tierDollars;
            }

            // Omni Credits
            const omniCredits = pmsDetails["OmniCredits"];
            if (omniCredits == 0) {
                if(document.getElementById("statusOmniCreditsPC")  !==  null) {
                    document.getElementById("statusOmniCreditsPC").classList.add("d-none");
                }
                if(document.getElementById("statusOmniCreditsMO")  !==  null) {
                    document.getElementById("statusOmniCreditsMO").classList.add("d-none");
                }
            } else {
                accountDropdownEl.querySelector("[data-profile-omni-credits]").textContent =omniCredits;
            }
        });
    }

    function handleOpenAccountDropdown() {
        accountInfoElList.forEach(el => el.removeEventListener("click", handleOpenAccountDropdown, {once: true}));
        pmsProfileDetails();
    }
    accountInfoElList.forEach(el => el.addEventListener("click", handleOpenAccountDropdown, {once: true}));
});
